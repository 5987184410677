body {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  line-height: 1.4;

  max-width: 800px;
  margin: 20px auto;
  padding: 0 10px;

  color: $text-main;
  background: $background;

  text-rendering: optimizeLegibility;
}

button, input, textarea {
  transition: background-color $animation-duration linear,
              border-color $animation-duration linear,
              color $animation-duration linear,
              box-shadow $animation-duration linear,
              transform $animation-duration ease;
}
