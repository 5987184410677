img {
  max-width: 100%;
}

hr {
  border: none;
  border-top: 1px solid $border;
}

table {
  border-collapse: collapse;
  margin-bottom: 10px;
  width: 100%;
}

td, th {
  padding: 6px;
  text-align: left;
}

th {
  border-bottom: 1px solid $border;
}

tbody tr:nth-child(even) {
  background-color: $background-alt;
}

::-webkit-scrollbar {
  height: 10px;
  width: 10px;
}

::-webkit-scrollbar-track {
  background: $background-alt;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb {
  background: $scrollbar-thumb;
  border-radius: 6px;
}

::-webkit-scrollbar-thumb:hover {
  background: $scrollbar-thumb-hover;
}