$background: #ffffff !default;
$background-alt: #efefef !default;

$text-main: #363636 !default;
$text-bright: #000000 !default;

$links: #0076d1 !default;
$focus: #0096bfab !default;
$border: #dbdbdb !default;
$code: #000000 !default;

$button-hover: #dddddd !default;
$animation-duration: 0.1s !default;

$scrollbar-thumb: darken($button-hover, 3%);
$scrollbar-thumb-hover: darken($button-hover, 10%);

$form-placeholder: #949494 !default;
$form-text: #000000 !default;

@import 'parts/core';