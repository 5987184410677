code, kbd {
  background: $background-alt;
  color: $code;
  padding: 5px;
  border-radius: 6px;
}

pre > code {
  padding: 10px;
  display: block;
  overflow-x: auto;
}